/* src/QrScanner.css */

/* Ajustes generales para el contenedor */
.qr-scanner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Distribuye el espacio entre la cámara y los botones */
    height: 100vh; /* Ocupa toda la altura de la ventana del navegador */
    width: 100vw; /* Ocupa toda la anchura de la ventana del navegador */
    padding: 0;
    box-sizing: border-box;
    position: relative;
    background-color: #000; /* Fondo negro para el contenedor */
  }
  
  /* Contenedor del video de la cámara */
  .webcam-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ocupa todo el ancho del contenedor */
    height: calc(100% - 80px); /* Resta el alto de los botones del contenedor */
    overflow: hidden; /* Evita el desbordamiento */
  }
  
  /* Ajusta el tamaño del video */
  .webcam-container video {
    width: 100%; /* Ocupa todo el ancho del contenedor */
    height: 100%; /* Ocupa toda la altura del contenedor */
    object-fit: cover; /* Asegura que el video cubra el contenedor sin distorsión */
  }
  
  /* Estilo del contenedor del resultado */
  .result-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white; /* Texto blanco para que contraste sobre el fondo oscuro */
    z-index: 10; /* Asegura que el texto esté sobre la cámara */
  }
  
  /* Estilo del texto del resultado */
  .result-text {
    color: black;
    font-weight: bold;
    font-size: 5rem; /* Ajusta el tamaño de fuente según sea necesario */
    padding: 10px;
    border-radius: 5px;
    text-shadow: 
      1px 1px 0 #fff,  
      -1px -1px 0 #fff,
      1px -1px 0 #fff,
      -1px 1px 0 #fff;
  }
  
  /* Estilo del contenedor de los botones */
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ocupa todo el ancho del contenedor */
    padding: 10px 0; /* Espacio adicional alrededor de los botones */
    background-color: #333; /* Fondo de los botones para un mejor contraste */
  }
  
  /* Estilo de los botones */
  .button-container button {
    margin: 0 10px; /* Espacio entre los botones */
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 120px; /* Tamaño fijo del botón */
    transition: background-color 0.3s ease; /* Efecto de transición para el color de fondo */
  }
  
  /* Botón morado */
  .button-container .btn-purple {
    background-color: #6a1b9a; /* Morado */
  }
  
  .button-container .btn-purple:hover {
    background-color: #4a0072; /* Morado más oscuro al pasar el ratón */
  }
  
  /* Botón naranja */
  .button-container .btn-orange {
    background-color: #fb8c00; /* Naranja */
  }
  
  .button-container .btn-orange:hover {
    background-color: #e65100; /* Naranja más oscuro al pasar el ratón */
  }
  
  /* Botón amarillo */
  .button-container .btn-yellow {
    background-color: #fbc02d; /* Amarillo */
  }
  
  .button-container .btn-yellow:hover {
    background-color: #f9a825; /* Amarillo más oscuro al pasar el ratón */
  }
  